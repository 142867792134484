import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import CtaSection from "../components/CtaSection";
import TwoColumnSection from "../components/TwoColumnSection";

const Page = ({ data }) => {
	return (
		<Layout
			pageMeta={{
				title: "Subscribe forms",
			}}>
			<div>
				<section className="aboveFold">
					<div className="container">
						<h1>Hosted subscribe forms</h1>

						<p className="maxWidth-750 text-large text-dark itemCenter mb-30">
							Collect subscribers effortlessly without touching a code. Sidemail
							subscribe forms are simple to set up, easy to share, and you can
							customize them to fit your brand.
						</p>

						<a
							className="button button--bright button--huge mb-70"
							href="https://client.sidemail.io/register"
							style={{ minWidth: 300 }}>
							Start 7-day trial
						</a>

						<div className="videoContainer">
							<video loop autoPlay playsInline muted height={300}>
								<source src="/assets/contacts.mp4" type="video/mp4" />
							</video>
						</div>
					</div>
				</section>

				<section className="container">
					<TwoColumnSection
						title="Subscribe form design"
						text="Our Subscribe forms are designed to give you the best-looking starting point that matches your brand with just a few clicks. If you're a bit of an artist yourself, you can take full control, and send the data with AJAX."
						list={[
							"Mobile-ready",
							"Upload your logo",
							"Match your brand colors",
							"Use your own domain",
						]}
						img={data.formDesignImage.childImageSharp.fluid}
					/>
				</section>

				<section className="container">
					<TwoColumnSection
						title="Stay organized"
						text={
							<>
								<p className="text">
									Automatically add new subscribers into groups. So you can
									quickly take action and use these groups to reach specific
									subscribers.
								</p>

								<p className="text text--isLast">
									Did Bob subscribe via blog? Let's add him to blog group. Use
									more specific groups and take it further, for example, coding
									tutorial.
								</p>
							</>
						}
						img={data.segmentationImage.childImageSharp.fluid}
					/>
				</section>

				<section className="container">
					<TwoColumnSection
						title="Automatic responses"
						text="You'll never get a second chance to make a first impression. Give yourself a head start. Set an automatic email response when someone subscribes."
						list={["No-code email templates", "Rich-text messages"]}
						img={data.autoResponderImage.childImageSharp.fluid}
					/>
				</section>

				<section className="container">
					<TwoColumnSection
						title="Key metrics"
						text="Take out the guesswork and learn what is working and where you can improve. See how your form is performing - only the metrics you care about, no bloat."
						list={[
							"Number of pageviews",
							"Number of subscribers",
							"Conversion rate",
						]}
						img={data.keyMetricsImage.childImageSharp.fluid}
					/>
				</section>

				<section className="moreFeaturesSection container">
					<ul>
						<li>
							<h4>Unsubscribe handling</h4>
							<p>
								One-click unsubscribe for the best customer experience. This
								will save you from spam reports which leads to a better sender
								reputation.
							</p>
						</li>

						<li>
							<h4>Mobile responsive</h4>
							<p>
								We understand that more and more traffic is going from mobile
								devices. All subscribe forms are optimized to look gorgeous on
								every screen.
							</p>
						</li>

						<li>
							<h4>Timezone detection</h4>
							<p>
								Your messages won't get buried. Sidemail detects contact's
								timezone and lets you set delivery based on it.
							</p>
						</li>

						<li>
							<h4>Contact properties</h4>
							<p>
								You're not tied up to pre-defined fields, collect any data you
								need to with custom contact properties.
							</p>
						</li>

						<li>
							<h4>Painless migration</h4>
							<p>
								Say goodbye to your old clunky software and get up and running
								in a few clicks with simple CSV migration.
							</p>
						</li>

						<li>
							<h4>More control with code</h4>
							<p>
								Use our AJAX enabled end-point to send the form data from your
								website to Sidemail.
							</p>
						</li>
					</ul>
				</section>

				<CtaSection />
			</div>
		</Layout>
	);
};

export default Page;

export const query = graphql`
	query {
		formDesignImage: file(
			relativePath: { eq: "sidemail-public--forms-design.png" }
		) {
			...twoColumnsImage
		}
		segmentationImage: file(
			relativePath: { eq: "sidemail-public--forms-segmentation.png" }
		) {
			...twoColumnsImage
		}
		autoResponderImage: file(
			relativePath: { eq: "sidemail-public--forms-autoresponder.png" }
		) {
			...twoColumnsImage
		}
		keyMetricsImage: file(
			relativePath: { eq: "sidemail-public--forms-metrics.png" }
		) {
			...twoColumnsImage
		}
	}
`;
